<template>
    <section :class="['absolute inset-y-0 pl-10 max-w-full flex', centered ? '' : 'right-0']">
        <div class="w-screen max-w-5xl">
            <div class="h-screen flex flex-col bg-white shadow-xl overflow-x-hidden">
                <div class="relative h-full">

                    <autralis-success-comp :show="saved" :text="successMessage"/>
                    <autralis-error-comp :show="error !== null" :text="error"/>

                    <autralis-modal-comp :modal="modal"/>
                    <autralis-info-comp :show="noChangesMessage !== null" :text="noChangesMessage"/>

                    <div class="bg-white mb-6 md:mb-0 pb-0 md:pb-6 sticky top-0 z-10">
                        <header id="add-user-header" class="space-y-1 py-2 px-6 bg-blue-700 ">
                            <div class="flex items-center justify-between">
                                <div class="flex space-x-4">
                                    <button @click="save" class="primary-button">
                                        <span v-if="saving" class="flex items-center">
                                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                        </span>
                                        <span v-if="!saving && type === 'add'"><i class="fal fa-plus mt-1 mr-1"></i> Add</span>
                                        <span v-if="!saving && type === 'edit'"><i class="fal fa-save mt-1 mr-1"></i> Save</span>
                                    </button>
                                </div>

                                <div class="flex">

                                    <div v-if="type==='edit'" class="border-r border-gray-100 border-opacity-30 pr-6 mr-6 relative">
                                        <i class="fal fa-ellipsis-h-alt text-white text-lg text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer"
                                           @click="menuOpen = !menuOpen;"
                                        />
                                        <div v-if="menuOpen" class="bg-white absolute right-0 rounded-md shadow-lg p-4 dropdown-nav" style="">
                                            <!-- DELETE BUTTON -->
                                            <div class="mb-2">
                                                <autralis-delete-user-comp :user-ids="[userProfile.userId]"
                                                                           :company-id="$store.getters['master/detail'].companyId"
                                                                           :page="$store.getters['master/detail'].page"
                                                                           :itemsPerPage="$store.getters['master/detail'].itemsPerPage"
                                                                           @on-success="changed('User status successfully changed.')"/>
                                            </div>

                                            <!-- RESET PASSWORD -->
                                            <autralis-company-user-reset-password-comp :user-ids="[userProfile.userId]"
                                                                                       :company-id="$store.getters['master/detail'].companyId"
                                                                                       @on-success="changed('Password successfully changed.')">
                                                <div class="flex cursor-pointer text-gray-500 hover:text-gray-800 font-medium">
                                                    <div><i class="fal fa-lock-alt mr-2"/>Reset password</div>
                                                </div>
                                            </autralis-company-user-reset-password-comp>
                                        </div>
                                    </div>

                                    <div class="hidden h-6 md:flex items-center mr-4">
                                        <i class="fas fa-arrows-alt-h text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="centered = !centered"/>
                                    </div>
                                    <div class="h-6 flex items-center">
                                        <i class="fal fa-times text-white text-2xl hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="close"/>
                                    </div>

                                </div>


                            </div>
                        </header>
                    </div>

                    <div v-if="saving" class="absolute inset-x-0 bottom-0 bg-black bg-opacity-30 z-20" :style="{top: headerHeight + 'px'}"/>
                    <!-- Go BACK if there is old state -->
                    <div
                        v-if="canGoBack"
                        @click="onBack"
                        class="flex items-center mb-6 px-6 cursor-pointer text-gray-600 text-sm hover:text-gray-800">
                        <i class="fal fa-chevron-left text-xs mr-2" style="margin-bottom: 1px;"></i>Go back
                    </div>
                    <div class="px-6">
                        <div v-if="type==='edit' && userStatus"
                             :class="['flex justify-between items-center mb-6 border-b border-t md:border-l md:border-r md:rounded-md px-0 md:px-6 py-2', 'border-' +
                        userStatus.color + '-200']">
                            <div class="flex flex-col md:flex-row">
                                <span>Current status:</span>
                                <div :class="'text-left ml-0 md:ml-2 text-' + userStatus.color +'-500 truncate font-semibold uppercase tracking-wider'">{{ userStatus.label }}</div>
                            </div>
                            <div>
                                <autralis-user-status-button-comp :user-ids="[userProfile.userId]"
                                                                  :company-id="$store.getters['master/detail'].companyId"
                                                                  :classNameConfig="{
                                                                        'wrapper': 'flex',
                                                                        'nextButton': 'ml-4'
                                                                  }"/>
                            </div>
                        </div>
                        <div class="flex items-center w-full border-b border-gray-200 mb-6 pb-6">
                            <div class="pr-4 text-blue-700 text-3xl">
                                <i class="fal fa-user"></i>
                            </div>
                            <div class="pl-2">
                                <div class="text-gray-600 text-2xl font-bold">User profile</div>
                                <div v-if="type === 'edit'" class="text-gray-600 text-sm">Edit or update the user profile info by changing the info below.</div>
                                <div v-if="type === 'add'" class="text-gray-600 text-sm">Fill the info below and click on save to add new company user.</div>
                            </div>
                        </div>
                    </div>

                    <autralis-loading-comp v-if="fetching" size="md"/>

                    <div v-else class="flex flex-col md:flex-row w-full px-6 overflow-y-scroll pb-6">
                        <div :class="['w-full md:w-1/2', isCompanyMenuSelected ? 'md:w-1/2' : 'md:w-2/3']">
                            <div class="flex flex-col items-center">

                                <div class="w-full">

                                    <div class="mb-4">
                                        <autralis-small-select-comp title="Title" :selectedValue="user.title" @on-change="handleChange('title', $event)" :values="userTitles"/>
                                    </div>
                                    <div class="w-full mb-4">
                                        <autralis-input-group-comp label="Username"
                                                                   :error="formErrors.username"
                                                                   :value="user.username"
                                                                   required
                                                                   placeholder="john.doe"
                                                                   @on-change="handleChange('username', $event)"/>

                                    </div>

                                    <div class="flex space-x-2 mb-4">
                                        <div class="w-1/2">
                                            <autralis-input-group-comp label="First name"
                                                                       :error="formErrors.firstName"
                                                                       :value="user.firstName"
                                                                       required
                                                                       placeholder="John"
                                                                       @on-change="handleChange('firstName', $event)"/>
                                        </div>
                                        <div class="w-1/2">
                                            <autralis-input-group-comp label="Last name"
                                                                       :error="formErrors.lastName"
                                                                       :value="user.lastName"
                                                                       required
                                                                       placeholder="Doe"
                                                                       @on-change="handleChange('lastName', $event)"/>
                                        </div>

                                    </div>
                                    <div class="w-full mb-4">
                                        <autralis-input-group-comp label="Email"
                                                                   :error="formErrors.email"
                                                                   :value="user.email"
                                                                   required
                                                                   placeholder="john.doe@company.com"
                                                                   @on-change="handleChange('email', $event)"/>

                                    </div>

                                    <div class="flex space-x-2 mb-4">
                                        <div class="w-1/2">
                                            <autralis-input-group-comp label="Mobile"
                                                                       :error="formErrors.mobile"
                                                                       :value="user.mobile"
                                                                       required
                                                                       placeholder="+32 486 31 12 36"
                                                                       @on-change="handleChange('mobile', $event)"/>
                                        </div>
                                        <div class="w-1/2">
                                            <autralis-input-group-comp label="Telephone"
                                                                       :error="formErrors.telephone"
                                                                       :value="user.telephone"
                                                                       required
                                                                       placeholder="+32 9 230 02 33"
                                                                       @on-change="handleChange('telephone', $event)"/>
                                        </div>

                                    </div>

                                    <div class="mb-4">
                                        <autralis-base-select-comp label="Select language"
                                                                   placeholder="Select language"
                                                                   :selected="user.culture"
                                                                   required
                                                                   @on-change="handleChange('culture', $event)"
                                                                   :options="Object.keys($language.available).map(key => ({ value: key, text: $language.available[key]}))"/>
                                    </div>

                                    <div class="flex mb-4">
                                        <div class="flex flex-col w-1/2">
                                            <label class="block text-sm font-medium mb-1 text-gray-700">Sms notifications</label>
                                            <div class="mt-2">
                                                <t-toggle v-model="user.smsActive"/>
                                            </div>
                                        </div>
                                        <div class="w-1/2">
                                            <div class="flex flex-col">
                                                <label class="block text-sm font-medium mb-1 text-gray-700">Tax included</label>
                                                <div class="mt-2">
                                                    <t-toggle v-model="user.offer_tax"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <autralis-roles-selector-comp :selectedRoles="user.roles ? user.roles : []"
                                                                  :error="formErrors.roles"
                                                                  @on-change="handleChange('roles', $event)"/>

                                </div>
                            </div>
                        </div>

                        <div v-if="isCompanyMenuSelected" class="flex flex-col justify-start w-full md:w-1/2 md:pl-6 mt-4 md:mt-0 md:ml-6 md:border-l md:border-gray-100">

                            <div class="">
                                <autralis-business-units-selector-comp :selectedBusinessUnits="user.businessUnits ? user.businessUnits : []"
                                                                       :selectedBrands="user.brands ? user.brands : []"
                                                                       :errors="businessUnitsErrors"
                                                                       @on-business-unit-change="handleBusinessUnitChange($event)"
                                                                       @on-brand-change="handleBrandChange($event)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SuccessBox from "@/components/success-box";
import InputGroup from "@/components/input-group";
import ErrorBox from "@/components/error-box";
import Modal from "@/components/modal";
import BaseSelect from "@/components/base-select";
import SmallSelect from "@/components/select-small-boxes";

import {modalInfo} from "@/common/modal";
import {filter, find, forEach, isEmpty, isEqual, map} from "lodash";
import {handleFetchCompanyUser, handleSaveUser} from "../handlers";
import {isValidEmail} from "../../../../../common";
import {INFO_BOX_TIMEOUT, MENU_KEYS, USER_TITLES} from "../../../../constants";
import RolesSelector from "./RolesSelector";
import InfoBox from "@/components/info-box";
import BusinessUnitsSelector from "./BusinessUnitsSelector";
import Loading from "@/components/loading";
import ResetPassword from "./ResetPassword";
import UserStatusButtons from "./UserStatusButtons";
import DeleteUser from "./DeleteUser";
import {generateTriggers, STATES} from "../../states-and-triggers";
import {getElementDimensionsById} from "@/master/utils";

const INIT_USER = {
    title: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    telephone: '',
    culture: '',
    smsActive: false,
    offer_tax: false,
    roles: [],
    businessUnits: [],
    brands: []
}

export default {
    name: "AddCompanyUser",
    data() {
        return {
            user: INIT_USER,
            initUser: INIT_USER,
            type: 'edit',
            centered: false,
            menuOpen: false,
            setDefaultLoading: false,
            saved: null,
            successMessage: '',
            setCompoundLoading: false,
            error: null,
            modal: modalInfo(),
            formErrors: {},
            saving: false,
            fetching: false,
            noChangesMessage: null,
            stateTriggers: [],
            headerHeight: 0
        }
    },

    computed: {
        isDefault() {
            return this.user.default;
        },

        isCompanyMenuSelected() {
            return this.$store.getters['master/application'].subMenu.methodName === MENU_KEYS.COMPANY
        },

        saveAllowed() {
            let allowed = true;
            if (this.type === 'edit') {
                allowed = !isEqual(this.user, this.initUser)
            }

            return allowed

        },

        // hasChanges: {
        //     cache: false,
        //     get: function () {
        //         let hasChanged = false
        //         if (this.type === 'edit') {
        //             hasChanged = !isEqual(this.user, this.initUser)
        //         }
        //
        //         return hasChanged
        //     }
        // },

        userTitles() {
            return USER_TITLES;
        },

        userStatus() {
            return STATES[this.userProfile.state] ? STATES[this.userProfile.state] : null;
        },

        businessUnitsErrors() {
            let errors = {};
            if (this.formErrors['businessUnits']) {
                errors['businessUnits'] = this.formErrors['businessUnits']
            }

            if (this.formErrors['brands']) {
                errors['brands'] = this.formErrors['brands']
            }

            return errors
        },

        userProfile() {
            const id = this.$store.getters['master/detail'].userId;
            if (id) {
                const user = find(this.$store.getters['master/companyUsers'], item => item.id === id);
                return user ? user : null
            }

            return null;
        },
        canGoBack() {
            return this.$store.getters['master/detail'].oldState && !isEmpty(this.$store.getters['master/detail'].oldState)
        }
    },

    methods: {
        onBack() {
            if (this.canGoBack) {
                const detailPayload = this.$store.getters['master/detail'].oldState
                this.$store.commit('master/setDetail', detailPayload);
                this.$store.commit('master/openDetail');
            }
        },
        close() {
            this.$store.commit('master/setDetail', null);
            this.$store.commit('master/closeDetail');
        },

        handleChange(field, value) {
            this.user = {...this.user, [field]: value}
        },

        changed(msg) {
            this.saved = true;
            setTimeout(() => this.saved = null, INFO_BOX_TIMEOUT)
            this.successMessage = msg;
            this.menuOpen = false
        },

        handleBusinessUnitChange(businessUnitId) {
            const businessUnit = find(this.user.businessUnits, bu => bu === businessUnitId)
            let newBusinessUnits, newBrands;
            if (businessUnit) {
                newBusinessUnits = filter(this.user.businessUnits, bu => bu !== businessUnitId) // Remove business unit
                newBrands = filter(this.user.brands, br => br.businessUnitId !== businessUnitId) // Remove brands
            } else {
                newBusinessUnits = [...this.user.businessUnits, businessUnitId] // ADD business unit
                newBrands = [...this.user.brands, {businessUnitId: businessUnitId, brands: []}] // ADD brands
            }

            this.user = {...this.user, businessUnits: newBusinessUnits, brands: newBrands}
        },

        handleBrandChange({brandId, businessUnitId}) {
            const selected = find(this.user.brands, br => br.businessUnitId === businessUnitId)
            if (selected) {
                const brands = selected.brands.includes(brandId) ? [...filter(selected.brands, br => br !== brandId)] : [...selected.brands, brandId]
                const updatedBrand = {...selected, brands}
                const newBrands = [...filter(this.user.brands, br => br.businessUnitId !== businessUnitId), updatedBrand]
                this.user = {...this.user, brands: newBrands}
            } else {
                this.user = {...this.user, brands: {businessUnitId: businessUnitId, brands: [brandId]}}
            }
        },

        setUser() {
            const id = this.$store.getters['master/detail'].userId;

            if (id) {
                this.fetchCompanyUser(this.userProfile.userId);
            } else {
                this.type = 'add'
            }
        },

        async fetchCompanyUser(user_id) {

            const payload = {
                userId: user_id,
                // companyId: this.$store.getters['master/company'].id
                companyId: this.$store.getters['master/detail'].companyId
            }

            this.fetching = true;
            this.error = null;

            await handleFetchCompanyUser(payload, (response) => {
                const {companyUser, companyUserBusinessUnits, companyUserBusinessUnitBrands} = response;
                const roles = map(companyUser.roles, role => role.id)

                const user = {...companyUser, businessUnits: companyUserBusinessUnits, brands: companyUserBusinessUnitBrands, roles};
                this.stateTriggers = generateTriggers([user.state])

                this.user = {...user}
                this.initUser = {...user};

            }, (error) => {
                this.error = error
            })

            this.fetching = false;
        },

        setStoreUsers(users) {
            this.$store.commit('master/setCompanyUsers', users)
        },

        async save() {
            if (this.isValid()) {
                if (this.saveAllowed) {
                    const payload = {
                        userId: this.user.userId ? this.user.userId : 0,
                        companyId: this.$store.getters['master/detail'].companyId,
                        page: this.$store.getters['master/detail'].page,
                        itemsPerPage: this.$store.getters['master/detail'].itemsPerPage,
                        user: this.user
                    }

                    this.saving = true;
                    this.error = null;

                    await handleSaveUser(payload, (response) => {
                        this.setStoreUsers(response.users)
                        if (this.type !== 'edit') {
                            this.user = INIT_USER;
                            this.initUser = INIT_USER;
                            if (!this.canGoBack) {
                                // If there is no oldState close details box
                                setTimeout(() => this.close(), INFO_BOX_TIMEOUT)
                            } else {
                                // Go to the previous state
                                setTimeout(() => this.onBack(), INFO_BOX_TIMEOUT)
                            }
                        }
                        this.successMessage = 'The changes saved successfully.'
                        this.saved = true;
                    }, (error) => {
                        this.error = error
                    })

                    this.saving = false;
                } else {
                    this.noChangesMessage = 'Nothing changed.';
                    setTimeout(() => this.noChangesMessage = null, INFO_BOX_TIMEOUT)
                }
            }
        },

        validateForm() {
            let isValid = true;
            let errors = {};

            if (!this.user.username) {
                errors['username'] = "The username cannot be empty.";
                isValid = false;
            }
            if (!this.user.firstName) {
                errors['firstName'] = "The first name cannot be empty.";
                isValid = false
            }
            if (!this.user.lastName) {
                errors['lastName'] = "The last name cannot be empty.";
                isValid = false
            }


            if (!isValidEmail(this.user.email)) {
                errors['email'] = "The format of the email address is not correct.";
                isValid = false
            }

            if (!this.user.mobile) {
                errors['mobile'] = "The mobile cannot be empty.";
                isValid = false
            }

            if (!this.user.telephone) {
                errors['telephone'] = "The telephone cannot be empty.";
                isValid = false
            }

            if (this.user.roles && this.user.roles.length === 0) {
                errors['roles'] = "Please choose at least one role.";
                isValid = false
            }

            if (!this.user.culture) {
                errors['culture'] = "The language cannot be empty.";
                isValid = false
            }

            if (this.isCompanyMenuSelected) {
                if (this.user.businessUnits && this.user.businessUnits.length === 0) {
                    errors['businessUnits'] = "Please choose at least one business unit.";
                    isValid = false
                }

                if (this.user.businessUnits && this.user.businessUnits.length > 0) {
                    if (this.user.brands && this.user.brands.length > 0) {
                        let brandErrors = {}
                        forEach(this.user.brands, br => {
                            if (br.brands.length === 0) {
                                brandErrors = {...brandErrors, [br.businessUnitId]: "Please choose at least one brand."}
                            }
                        })
                        if (!isEmpty(brandErrors)) {
                            errors['brands'] = brandErrors
                            isValid = false
                        }
                    }
                }
            }

            return {
                isValid,
                errors
            }
        },

        isValid() {
            this.formErrors = {};
            const {isValid, errors} = this.validateForm();
            this.formErrors = {...errors}
            return isValid
        }
    },

    created() {
        this.setUser();
    },

    mounted() {
        const header = getElementDimensionsById("add-user-header")
        if (header) {
            this.headerHeight = header.height
        }
    },

    components: {
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
        'autralis-modal-comp': Modal,
        'autralis-input-group-comp': InputGroup,
        'autralis-base-select-comp': BaseSelect,
        'autralis-roles-selector-comp': RolesSelector,
        'autralis-info-comp': InfoBox,
        'autralis-loading-comp': Loading,
        'autralis-business-units-selector-comp': BusinessUnitsSelector,
        'autralis-small-select-comp': SmallSelect,
        'autralis-company-user-reset-password-comp': ResetPassword,
        'autralis-user-status-button-comp': UserStatusButtons,
        'autralis-delete-user-comp': DeleteUser,
    }
}
</script>

<style scoped>
.dropdown-nav {
    top: 100%;
    min-width: 150px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

</style>